import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import useWebSocket from 'react-use-websocket';

import Login from './components/Login/Login';
import { WS_URL } from './constants';


function App() {
  let host = "";
  if ("development" === process.env.NODE_ENV) {
    host = WS_URL;
  } else if ("production" === process.env.NODE_ENV) {
    // host = "ws://" + document.location.hostname + ":6789/";

    // This host assumes an webserver setup to proxy websockets on the url '/wss'
    host = (document.location.protocol === "https:" ? "wss:" : "ws:") + "//" + document.location.host + "/wss";
  }
  console.log("Host = ", host);

  const [token, setToken] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [connected, setConnected] = useState(false);
  const { sendJsonMessage } = useWebSocket(host, {
    onOpen: () => {
      console.log("Websocket opened!");
      setConnected(true);
      // sendJsonMessage({'something': 'value'});
    },
    onError: () => {
      console.log("Websocket errored ...");
    },
    onMessage: (event) => {
      console.log("Websocket message: ", event);
    },
    onClose: () => {
      console.log("Websocket closed");
      setConnected(false);
      setLoggedIn(false);
    },
    share: true,
    shouldReconnect: (closeEvent) => true,
  })


  if(!connected) {
    return <p>Loading ...</p>;
  }

  if(!loggedIn) {
    return <Login setLoggedIn={setLoggedIn} setToken={setToken} wshost={host} />
  }

  return (
    <div className="wrapper">
      <h1>Application</h1>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<p>app</p>}/>
        </Routes>
      </BrowserRouter>
    </div>  
  );
}

export default App;
