import React, { useEffect, useState } from 'react'
import './Login.css';
import PropTypes from 'prop-types';
import useWebSocket from 'react-use-websocket';
import { v4 as uuidv4 } from 'uuid';
import { WS_URL } from '../../constants';

export default function Login({ setLoggedIn, setToken, wshost }) {
    let storageUsername = "";
    let storagePassword = "";
    let storageToken = "";
    let storageDeviceId = "";

    if (typeof (Storage) !== "undefined") {
        if ("username" in localStorage) {
            storageUsername = localStorage.username;
        }
        if ("password" in localStorage) {
            storagePassword = localStorage.password;
        }
        if ("token" in localStorage) {
            storageToken = localStorage.token;
        }
        if ("deviceid" in localStorage) {
            storageDeviceId = localStorage.deviceid;
        }
    }
    const [username, setUserName] = useState(storageUsername);
    const [password, setPassword] = useState(storagePassword);
    // const [working, setWorking] = useState(false);

    if("" === storageDeviceId) {
        storageDeviceId = uuidv4();
        console.log("Generated a device id: ", storageDeviceId);
    }

    const {
        // sendMessage,
        sendJsonMessage,
        // lastMessage,
        // lastJsonMessage,
        // readyState,
        // getWebSocket,
    } = useWebSocket(wshost, {
        onMessage: (event) => {
            const ev = JSON.parse(event.data);
            console.log("event.data", ev);
            // setWorking(false);
            if (("response" in ev) && ("login" === ev.response) && 
                ("result" in ev) && ("success" === ev.result)) {
                console.log("Login succeeded, saving token: ", ev.token);
                setToken(ev.token);
                setLoggedIn(true);
                if (typeof (Storage) !== "undefined") {
                    localStorage.token = ev.token;
                    localStorage.deviceid = storageDeviceId;
                }
            } else if (("response" in ev) && ("validate-token" === ev.response) && 
                       ("result" in ev) && ("success" === ev.result)) {
                console.log("Token validated, token: ", storageToken);
                setLoggedIn(true);
            } else {
                console.log("Validate token failed");
                setLoggedIn(false);
            }
        },
        share: true,
        filter: () => false
    })

    useEffect(() => {
        if (("" !== storageToken) /* && !working */) {
            // setWorking(true);
            sendJsonMessage({ 
                'action': 'validate-token', 
                'username': username, 
                'token': storageToken, 
                'deviceid': storageDeviceId 
            });
        }
        return () => {};
    }, [/* working, setWorking, */ sendJsonMessage, storageToken, storageDeviceId, username]);


    const handleSubmit = e => {
        e.preventDefault();
        console.log("handleSubmit:", username, password);
        // setWorking(true);
        sendJsonMessage({ 
            'action': 'login', 
            'username': username, 
            'password': password, 
            'deviceid': storageDeviceId 
        });
        if (typeof (Storage) !== "undefined") {
            localStorage.username = username;
            localStorage.password = password;
        }

    };

    return (
        <div className="login-wrapper">
            <h1>Please Log In</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Username</p>
                    <input type="text" value={username} onChange={e => setUserName(e.target.value)} size={40}/>
                </label>
                <label>
                    <p>Password</p>
                    <input type="password" value={password} onChange={e => setPassword(e.target.value)} size={40}/>
                </label>
                <div>
                    <button type="submit">Login</button>
                </div>
            </form>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired,
    setLoggedIn: PropTypes.func.isRequired,
    wshost: PropTypes.string.isRequired
}